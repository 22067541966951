import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  selectedHeader: 'Home'
}

const generalSlice = createSlice({
  name: 'generalSlice',
  initialState: initialState,
  reducers: {
    setSelectedHeader: (state, action) => {
      state.selectedHeader = action.payload
    },
  },
})

export const { setSelectedHeader } = generalSlice.actions
export const generalReducer = generalSlice.reducer
