import React, { useEffect, useState } from "react";
import { MECHANICAL_SERVICES } from "../constants";

const MechanicalServicePage = () => {

  const [ screenSize, setScreenSize ] = useState( "large" )
  const [ gridCols, setGridCols ] = useState( 'grid-cols-4' )

  useEffect( () => {
    const updateLogoSize = () => {
      if ( window.innerWidth < 400 ) {
        setScreenSize( 'x-small' )
      }else if ( window.innerWidth < 580 ) {
        setScreenSize( 'small' )
      } else if ( window.innerWidth < 900 ) {
        setScreenSize( 'medium' )
      } else {
        setScreenSize( 'large' )
      }

      if ( window.innerWidth < 550 ) {
        setGridCols( 'grid-cols-1' )
      } else if ( window.innerWidth < 850 ) {
        setGridCols( 'grid-cols-2' )
      } else if ( window.innerWidth < 1090 ) {
        setGridCols( 'grid-cols-3' )
      } else {
        setGridCols( 'grid-cols-4' )
      }
    };

    updateLogoSize();

    window.addEventListener( 'resize', updateLogoSize );

    return () => window.removeEventListener( 'resize', updateLogoSize );
  }, [] );

  return (
    <div
      className="flex flex-col items-center gap-20 py-20"
      style={{ backgroundColor: "#161618", minHeight: "calc(100vh - 85px)" }}
    >
      <div className="flex flex-col w-full items-center gap-2">
        <h1
          className={`font-semibold ${screenSize === 'x-small' ? 'text-2xl' : screenSize === 'small' ? 'text-3xl' : 'text-4xl'} text-center`}
          style={{
            color: "#FFFFFF",
            display: "inline-block",
            lineHeight: "80px",
          }}
        >
          MECHANICAL SERVICES
        </h1>
        <p className={`text-[#8A8F98] font-semibold ${screenSize === 'x-small' ? 'text-[17px] w-[18rem]' : screenSize === 'medium' || screenSize === 'large' ? 'w-[40rem] text-xl' : 'w-[25rem] text-md'} text-center`}>
          "Our innovative technology and trained expertise ensures
          specifications are met for even the most complex designs."
        </p>
      </div>

      <div class={`grid ${gridCols} gap-8`}>
        {MECHANICAL_SERVICES.map((item) => {
          return (
            <div class="border-solid border bg-[#1D1D1F] border-[#2E2E30] p-4 h-64 w-60 cursor-pointer rounded-md transition-transform duration-500 transform hover:-translate-y-3 hover:shadow-gray-700 shadow">
              <div className="flex flex-col gap-4 items-start">
                <div className="text-[#5E6AD2] text-3xl">{item.icon}</div>
                <p className="text-white font-semibold">{item.heading}</p>
                <p className="text-[#8A8F98]">{item.subHeading}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MechanicalServicePage;
