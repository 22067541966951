const Logo = ( { width=150, height=50} ) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width={`${width}pt`} height={`${height}pt`} viewBox="0 0 1278.000000 159.000000"
     preserveAspectRatio="xMidYMid meet">
    
    <g transform="translate(0.000000,159.000000) scale(0.100000,-0.100000)"
    fill="#8A6AF1" stroke="none">
    <path d="M4244 1326 c-201 -57 -335 -177 -417 -372 -65 -153 -79 -325 -37
    -443 36 -103 90 -171 175 -223 223 -135 567 -76 757 131 61 67 139 214 163
    311 21 82 22 230 2 297 -19 65 -30 86 -79 151 -108 146 -349 209 -564 148z
    m274 -181 c112 -52 159 -144 149 -294 -15 -230 -154 -413 -344 -452 -54 -11
    -69 -10 -125 5 -59 16 -118 51 -145 86 -39 48 -53 101 -53 194 0 80 5 107 30
    178 36 96 107 200 167 241 101 70 226 86 321 42z"/>
    <path d="M8148 1332 l-67 -3 -10 -37 c-11 -40 -30 -127 -147 -652 -97 -439
    -91 -398 -62 -405 13 -3 196 -5 406 -3 323 3 383 5 395 18 8 8 21 44 31 79 14
    52 15 66 4 73 -7 4 -138 8 -292 7 -173 0 -282 3 -288 9 -7 7 -2 46 15 119 32
    137 79 344 112 498 15 66 35 156 46 200 10 44 15 82 11 86 -10 10 -76 14 -154
    11z"/>
    <path d="M11629 1328 c-10 -4 -19 -10 -19 -12 0 -6 -100 -401 -130 -511 -19
    -71 -57 -208 -121 -443 -11 -40 -19 -85 -17 -100 l3 -27 113 -3 c61 -1 120 2
    131 7 17 10 47 104 107 334 9 37 24 72 33 76 9 5 122 11 251 14 203 4 245 7
    309 26 99 29 145 54 206 112 75 71 108 142 113 245 4 75 2 87 -21 126 -14 24
    -36 53 -49 65 -35 33 -116 69 -183 82 -67 13 -694 21 -726 9z m655 -198 c37
    -26 46 -45 46 -97 0 -100 -82 -173 -217 -193 -109 -15 -332 -13 -339 4 -5 14
    60 265 78 300 9 17 22 18 203 14 184 -4 195 -5 229 -28z"/>
    <path d="M521 1313 c-5 -10 -18 -63 -30 -118 -11 -55 -63 -289 -115 -520 -67
    -297 -91 -424 -84 -433 13 -15 543 -17 667 -2 169 22 297 94 348 197 24 49 28
    70 28 139 0 72 -3 85 -27 120 -16 23 -50 52 -83 69 -30 17 -55 33 -55 37 0 3
    25 21 55 38 67 38 122 100 136 154 30 110 -21 240 -111 286 -84 43 -148 50
    -440 50 -256 0 -280 -1 -289 -17z m563 -174 c51 -23 70 -93 43 -157 -15 -37
    -50 -65 -97 -80 -38 -12 -355 -20 -365 -10 -12 12 45 242 63 257 22 17 314 9
    356 -10z m-101 -448 c69 -11 117 -59 117 -117 0 -74 -30 -119 -100 -150 -27
    -12 -79 -16 -235 -17 l-200 -2 -3 31 c-3 35 44 232 60 251 12 15 279 17 361 4z"/>
    <path d="M1716 1313 c-3 -10 -12 -56 -20 -103 -9 -47 -27 -130 -40 -185 -14
    -55 -39 -161 -55 -235 -17 -74 -48 -211 -69 -305 -58 -250 -57 -243 -30 -250
    42 -9 877 0 887 10 14 14 35 129 28 148 -6 15 -38 17 -332 17 -276 0 -326 2
    -331 15 -10 26 52 265 74 282 11 9 91 12 303 11 170 -1 293 2 298 8 12 13 45
    154 37 161 -3 4 -138 7 -299 7 l-292 1 -3 29 c-4 33 35 204 50 223 8 10 89 13
    322 15 l311 3 12 55 c6 30 12 67 12 83 l1 27 -429 0 c-401 0 -430 -1 -435 -17z"/>
    <path d="M2720 1311 c0 -17 97 -221 205 -431 57 -112 95 -207 95 -239 0 -15
    -18 -107 -40 -205 -33 -143 -38 -181 -28 -193 18 -22 181 -15 204 8 15 16 54
    160 85 314 9 45 24 84 44 111 46 64 366 471 434 553 34 40 61 79 61 87 0 11
    -24 14 -127 14 l-128 -1 -120 -160 c-66 -88 -142 -191 -168 -227 -27 -37 -53
    -67 -59 -67 -6 0 -47 77 -90 170 -43 94 -90 195 -106 225 l-27 55 -117 3
    c-111 2 -118 1 -118 -17z"/>
    <path d="M5233 1318 c-11 -14 -24 -68 -134 -563 -38 -176 -79 -355 -91 -399
    -30 -116 -23 -126 84 -126 46 0 89 4 96 8 16 11 24 40 68 242 70 325 94 410
    118 410 30 0 51 -38 167 -305 22 -49 44 -99 49 -110 5 -11 27 -64 51 -118 23
    -54 51 -104 62 -112 15 -10 47 -15 100 -15 104 0 115 11 144 145 53 244 96
    434 138 620 64 281 68 302 62 319 -5 13 -25 16 -102 16 -110 0 -102 7 -130
    -118 -122 -565 -131 -598 -151 -581 -8 6 -21 26 -28 43 -8 17 -34 76 -59 131
    -60 133 -76 171 -126 295 -23 58 -56 132 -74 165 l-32 60 -101 3 c-73 2 -104
    -1 -111 -10z"/>
    <path d="M6426 1308 c-13 -21 -236 -1006 -236 -1042 0 -33 48 -38 315 -33 296
    6 357 16 467 74 124 65 239 205 288 353 27 79 37 247 21 335 -22 118 -96 227
    -187 277 -88 48 -162 58 -419 58 -232 0 -235 0 -249 -22z m513 -172 c39 -21
    86 -77 102 -123 20 -56 17 -224 -5 -298 -38 -126 -114 -231 -200 -274 -48 -24
    -169 -40 -304 -41 -77 0 -80 5 -60 103 67 323 142 634 156 646 23 18 270 8
    311 -13z" />
    <path d="M10615 1315 c-109 -19 -130 -32 -581 -342 -67 -45 -130 -83 -139 -83
    -19 0 -96 49 -275 174 -201 142 -259 166 -396 166 -76 0 -93 -4 -157 -33 -152
    -71 -241 -181 -272 -336 -16 -75 -16 -87 -1 -161 19 -90 68 -184 125 -238 90
    -84 238 -139 346 -129 101 10 170 43 396 194 118 78 225 143 237 143 12 0 140
    -79 284 -177 275 -184 332 -216 426 -239 165 -39 359 19 489 145 74 72 102
    113 134 201 20 56 24 82 23 185 0 137 -17 197 -81 294 -79 121 -228 219 -363
    241 -80 12 -106 12 -195 -5z m188 -160 c83 -21 125 -46 185 -106 72 -72 112
    -163 112 -256 0 -242 -204 -420 -437 -383 -89 14 -109 25 -308 157 -254 169
    -285 192 -285 212 0 20 29 48 85 82 22 13 45 28 52 34 6 5 28 21 48 35 20 14
    55 39 78 56 86 62 223 143 267 158 88 29 127 31 203 11z m-1455 -114 c37 -15
    99 -50 137 -76 233 -160 235 -161 235 -184 -1 -18 -3 -20 -190 -149 -170 -118
    -207 -135 -286 -134 -81 1 -132 15 -181 51 -81 58 -120 150 -110 256 12 124
    97 230 201 251 10 2 21 6 24 9 16 15 109 3 170 -24z" fill="#D73AD8"/>
    </g>
    </svg>
    

    

  );
};

export default Logo;
