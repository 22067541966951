import { configureStore } from '@reduxjs/toolkit'
import { generalReducer } from './general/slice'

const store = configureStore({
  reducer: {
    generalSlice: generalReducer,
  },
})

export default store
