import React, { useEffect, useState } from "react";
import { TiTick } from "react-icons/ti";
import { INDUSTRIES, INDUSTRIES_WE_SERVE } from "../constants";

const IndustriesPage = () => {

  const [ screenSize, setScreenSize ] = useState( "large" )


  useEffect( () => {
    const updateLogoSize = () => {
      if ( window.innerWidth < 400 ) {
        setScreenSize( 'x-small' )
      }else if ( window.innerWidth < 620 ) {
        setScreenSize( 'small' )
      } else if ( window.innerWidth < 900 ) {
        setScreenSize( 'medium' )
      } else {
        setScreenSize( 'large' )
      }
    };

    updateLogoSize();

    window.addEventListener( 'resize', updateLogoSize );

    return () => window.removeEventListener( 'resize', updateLogoSize );
  }, [] );

  return (
    <div
      className="flex flex-col items-center gap-20 py-20"
      style={{ backgroundColor: "#161618", minHeight: "calc(100vh - 85px)" }}
    >
      <div className="flex flex-col w-full items-center gap-2">
        <h1
          className={`font-semibold ${screenSize==='x-small'?'text-2xl':screenSize === 'small' ? 'text-3xl' : 'text-4xl'} text-center`}
          style={{
            color: "#FFFFFF",
            display: "inline-block",
            lineHeight: "80px",
          }}
        >
          INDUSTRIES WE SERVE
        </h1>
        <p className={`text-[#8A8F98] font-semibold text-xl ${screenSize==='x-small'?'text-[17px] w-[18rem]':screenSize === 'medium' || screenSize === 'large' ? 'w-[40rem] text-xl' : 'w-[25rem] text-md'} text-center`}>
          "Perfect Blend of Innovation & Ideation Of Software and Design For
          Every Industry."
        </p>
      </div>

      <div className="w-[80%] px-4 py-6">
        <div className="flex flex-col gap-2 items-start">
          <div>
            <p className={`text-white text-base`}>
              Our industry specific solutions streamlined business process along
              with efficient customer’s engagement. We identify opportunities to
              provide competitive advantages to our clients by leveraging the
              latest technology. Boost your revenue and drive operational
              excellence with our customized industries solutions. In order to
              meet the unique requirements of every industries, they need to be
              supported with software solutions and services. Our new age
              industrial solutions and services are designed to help business to
              propel forward and achieve excellence. Our domain expertise meets
              industry specific challenges. We strive to create cost effective
              products that help companies to gain the competitive edge.
            </p>
          </div>
          <div>
            {INDUSTRIES.map((item) => {
              return (
                <div className="flex items-center justify-start gap-2 mt-5">
                  <div className="text-[#5E6AD2] text-2xl">
                    <TiTick />
                  </div>
                  <span className="text-[#8A8F98] font-semibold">{item}</span>
                </div>
              );
            })}
          </div>
          <div>
            <p className="text-white text-base mt-5">
              We deliver agile and intelligent industry services that lead to
              improved business performance and increase return on investment.
              We offer solutions for a wide array of different industries.
            </p>
          </div>
          <div className="flex flex-wrap gap-4">
            {INDUSTRIES_WE_SERVE.map((item) => {
              return (
                <div className="px-3 py-1 border border-[#5E6AD2] bg-[#5E6AD2] text-white font-semibold rounded-2xl mt-5">
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustriesPage;
