import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedHeader } from "../store/general/selector";
import { setSelectedHeader } from "../store/general/slice";
import { HEADER_TABS } from "../constants";
import Logo from "./Logo";
import { useLocation, useNavigate } from "react-router-dom";
import { IoChevronUpSharp, IoChevronDownSharp } from "react-icons/io5";
import { throttle } from 'lodash';
import { HamburgerIcon } from '../common/icons'

const Header = ( { scrollToAbout, setShouldScrollToAbout, mainContentRef } ) => {

  const selectedHeader = useSelector( selectSelectedHeader )
  const [ showServicesDropdown, setShowServicesDropdown ] = useState( false )
  const [ headerBackground, setHeaderBackground ] = useState( 'bg-transparent' )
  const [ logoSize, setLogoSize ] = useState( { width: 150, height: 50 } );
  const [ smallScreenModeActive, setSmallScreenModeActive ] = useState( false )
  const [ smallScreenMenuOpen, setSmallScreenMenuOpen ] = useState( false )

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();

  const handleItemClick = ( item ) => {
    if (item !== 'Services') {
      setSmallScreenMenuOpen(false)
    }

    if ( item === 'Contact' ) {
      navigate( '/contact' )
    } else if ( item === 'Services' ) {
      setShowServicesDropdown( !showServicesDropdown )
    } else if ( item === 'Mechanical' ) {
      navigate( '/MechanicalServices' )
      setShowServicesDropdown( '' )
    } else if ( item === 'IT' ) {
      navigate( '/ITServices' )
      setShowServicesDropdown( '' )
    } else if ( item === 'About' ) {
      if ( location.pathname === '/' ) {
        scrollToAbout();
      } else {
        setShouldScrollToAbout( true )
        navigate( '/' );
      }
    } else if ( item === 'Industries' ) {
      navigate( '/industries' )
    } else {
      dispatch( setSelectedHeader( item ) )
    }
  }

  const logoHandler = () => {
    setShouldScrollToAbout( false )
    navigate( '/' )
  }

  const dropdownRef = useRef( null )

  const smallScreenMenuRef = useRef( null )

  const smallScreenMenuSubItemRef = useRef( null )


  const handleClickOutside = ( event ) => {
    if ( smallScreenMenuRef.current && !smallScreenMenuRef.current.contains(event.target)) {
      setSmallScreenMenuOpen( false )
    }

    if ( dropdownRef.current && !dropdownRef.current.contains( event.target ) ) {
      setShowServicesDropdown( false )
      setSmallScreenMenuOpen(false)
    }
  }

  useEffect( () => {
    document.addEventListener( 'mousedown', handleClickOutside )
    return () => {
      document.removeEventListener( 'mousedown', handleClickOutside )
    }
  }, [] )


  useEffect( () => {
    const handleScroll = throttle( () => {
      if ( mainContentRef.current && mainContentRef.current.scrollTop > 20 ) {
        setHeaderBackground( 'bg-[#161618]' )
      } else {
        setHeaderBackground( 'bg-transparent' )
      }
    }, 100 )

    const scrollContainer = mainContentRef.current
    if ( scrollContainer ) {
      scrollContainer.addEventListener( 'scroll', handleScroll )
    }

    return () => {
      if ( scrollContainer ) {
        scrollContainer.removeEventListener( 'scroll', handleScroll )
      }
      handleScroll.cancel()
    }
  }, [ mainContentRef ] )


  useEffect( () => {
    const updateLogoSize = () => {
      if ( window.innerWidth >= 850 ) {
        setLogoSize( { width: 150, height: 50 } );
      } else {
        setLogoSize( { width: 130, height: 50 } );
      }

      if ( window.innerWidth < 700 ) {
        setSmallScreenModeActive( true )
      } else {
        setSmallScreenModeActive( false )
        setSmallScreenMenuOpen(false)
      }
    };

    updateLogoSize();

    window.addEventListener( 'resize', updateLogoSize );

    return () => window.removeEventListener( 'resize', updateLogoSize );
  }, [] );

  return (
    <div
      className={`flex justify-between items-center py-6 px-14 md:px-20 h-[80px] sticky top-0 w-screen bg-[#161618] z-10 ${headerBackground}`}
    >
      <div onClick={logoHandler} style={{ cursor: 'pointer' }}>
        <Logo width={logoSize.width} height={logoSize.height} />
      </div>
      {
        smallScreenModeActive ? <div className='cursor-pointer' onClick={() => {
          setSmallScreenMenuOpen( !smallScreenMenuOpen )
        }}><HamburgerIcon color='#b5b5b5'/></div> : <ul className='flex gap-10 text-base'>
          {HEADER_TABS.map( ( item, i ) => {
            return (
              <li
                key={i}
                className={`relative group ${item === 'Services' ? 'z-10' : ''}`}
              >
                <div
                  className={`text-white cursor-pointer font-semibold hover:text-[#565656] flex items-center gap-2 max-[849px]:text-sm text-md  ${item === selectedHeader && 'text-[#858585]'
                    }`}
                  onClick={() => handleItemClick( item )}
                >
                  {item}{' '}
                  {item === 'Services' &&
                    ( showServicesDropdown ? (
                      <IoChevronUpSharp />
                    ) : (
                      <IoChevronDownSharp />
                    ) )}
                </div>
                {item === 'Services' && showServicesDropdown && (
                  <div
                    ref={dropdownRef}
                    className='absolute bg-white z-10 shadow-md mt-5 w-36 border-t-4 border-[#5E6AD2]'
                  >
                    <ul>
                      <li
                        className='py-1 px-3 cursor-pointer hover:bg-gray-200'
                        onClick={() => handleItemClick( 'IT' )}
                      >
                        IT
                      </li>
                      <li
                        className='py-1 px-3 cursor-pointer hover:bg-gray-200'
                        onClick={() => handleItemClick( 'Mechanical' )}
                      >
                        Mechanical
                      </li>
                    </ul>
                  </div>
                )}
              </li>
            )
          } )}
        </ul>
      }
      {
        <div className={`absolute transition-all ease-in-out bg-white border-t-4 border-[#5E6AD2] shadow-md top-[100%] left-0 w-[100%] ${!smallScreenMenuOpen && 'z-[-100] -translate-y-[350px] transition-all ease-in-out'}`} ref={smallScreenMenuRef}>
          <ul className='flex flex-col text-base'>
            {HEADER_TABS.map( ( item, i ) => {
              return (
                <li
                  key={i}
                  className={`relative group ${item === 'Services' ? 'z-10' : ''} py-2 px-5`}
                >
                  <div
                    className={`text-black cursor-pointer font-semibold hover:bg-gray-200 py-1 px-2 flex items-center justify-between max-[849px]:text-sm text-md  ${item === selectedHeader && 'text-[#858585]'
                      }`}
                    onClick={() => handleItemClick( item )}
                  >
                    {item}{' '}
                    {item === 'Services' &&
                      ( showServicesDropdown ? (
                        <IoChevronUpSharp />
                      ) : (
                        <IoChevronDownSharp />
                      ) )}
                  </div>
                  {item === 'Services' && showServicesDropdown && (
                    <div
                      ref={smallScreenMenuSubItemRef}
                      className='my-2'
                    >
                      <ul>
                        <li
                          className='py-1 px-5 cursor-pointer text-sm hover:bg-gray-200 text-[#181818]'
                          onClick={() => handleItemClick( 'IT' )}
                        >
                          IT
                        </li>
                        <li
                          className='py-1 px-5 cursor-pointer hover:bg-gray-200 text-sm text-[#181818]'
                          onClick={() => handleItemClick( 'Mechanical' )}
                        >
                          Mechanical
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
              )
            } )}
          </ul>
        </div>
      }
    </div>
  )
}

export default Header;
