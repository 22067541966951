import React from 'react'
import LandingPage from '../components/LandingPage'
import ClientsPage from '../components/ClientsPage'
import AboutPage from '../components/AboutPage'
// import ReviewPage from '../components/ReviewPage'

const MainContent = ({innerRef}) => {
  return (
    <>
      <LandingPage/>
      <ClientsPage />
      <AboutPage innerRef={innerRef} />
       {/* <ReviewPage /> */}
    </>
  )
}

export default MainContent