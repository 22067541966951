import React, { useEffect, useState } from "react";
import Logo from "./Logo";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FaRegCopyright } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

const FooterPage = ({ scrollToAbout, setShouldScrollToAbout, scrollToTop }) => {
  const [screenSize, setScreenSize] = useState("large");

  const navigate = useNavigate();
  const location = useLocation();

  const aboutHandler = () => {
    if (location.pathname === "/") {
      scrollToAbout();
    } else {
      setShouldScrollToAbout(true);
      navigate("/");
    }
  };
  const itHandler = () => {
    navigate("/ITServices");
  };
  const mechanicalHandler = () => {
    navigate("/MechanicalServices");
  };

  useEffect(() => {
    const updateLogoSize = () => {
      if (window.innerWidth < 550) {
        setScreenSize("small");
      } else if (window.innerWidth < 980) {
        setScreenSize("medium");
      } else {
        setScreenSize("large");
      }
    };

    updateLogoSize();

    window.addEventListener("resize", updateLogoSize);

    return () => window.removeEventListener("resize", updateLogoSize);
  }, []);

  return (
    <div className="py-10 px-10 bg-black flex flex-col gap-4 items-center">
      <div
        className={`flex ${
          screenSize === "medium" || screenSize === "small"
            ? ""
            : "items-center"
        } ${
          screenSize === "small" ? "flex-col" : ""
        } justify-center gap-14 w-full pb-5`}
      >
        <div
          className={`flex flex-col items-start gap-5 ${
            screenSize === "small" ? "w-[100%]" : "w-[40%]"
          } h-52`}
        >
          <div style={{ cursor: "pointer" }} onClick={scrollToTop}>
            <Logo />
          </div>
          <p className="text-white text-sm">
            Quality in a service or product is not what you put into it. It is
            what the client or customer get out of it.
          </p>
          <div className="flex gap-4 items-center justify-start text-xl mt-5">
            <span className="text-[#2864AA] cursor-pointer">
              <a
                href="https://www.linkedin.com/company/beyond-loop/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#2864AA] cursor-pointer"
              >
                <IoLogoLinkedin />
              </a>
            </span>
            <span className="text-white cursor-pointer">
              <a
                href="https://x.com/beyond_loop"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#2864AA] cursor-pointer"
              >
                <FaSquareXTwitter />
              </a>
            </span>
          </div>
        </div>

        <div
          className={`flex ${
            screenSize === "medium" || screenSize === "small"
              ? "flex-col gap-1"
              : "gap-10"
          }`}
        >
          <div className="flex flex-col gap-2 h-52 w-64">
            <h2 className="text-white font-semibold">Common Links</h2>
            <div className="mt-5 flex flex-col gap-3 text-sm">
              <div
                className="text-white flex gap-2 items-center cursor-pointer"
                onClick={aboutHandler}
              >
                <IoIosArrowForward />
                About Us
              </div>
              <div
                className="text-white flex gap-2 items-center cursor-pointer"
                onClick={itHandler}
              >
                <IoIosArrowForward />
                IT Services
              </div>
              <div
                className="text-white flex gap-2 items-center cursor-pointer"
                onClick={mechanicalHandler}
              >
                <IoIosArrowForward />
                Mechanical Services
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 h-52 w-64">
            <h2 className="text-white font-semibold">Support</h2>
            <div className="mt-5 flex flex-col gap-3 text-sm">
              <div className=" flex gap-3 items-center">
                <div className="text-[#5E6AD2]">
                  <FaLocationDot />
                </div>
                <p className="text-white"> Bhubaneshwar, Odisha, India</p>
              </div>
              <div className="flex gap-3 items-center">
                <div className="text-[#5E6AD2]">
                  <FaPhone />
                </div>
                <p className="text-white">+91 7504012736</p>
              </div>
              <div className="flex gap-3 items-center">
                <div className="text-[#5E6AD2]">
                  <IoMdMail />
                </div>
                <p className="text-white">support@beyondloop.in</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-around gap-4 text-sm">
        <div className="flex items-start gap-2 text-[#8A8F98]">
          <span className="mt-1">
            <FaRegCopyright />
          </span>
          <span>Copyright 2024 BEYOND LOOP. All Rights Reserved</span>
        </div>
        <div>
          <span className="text-[#8A8F98]">Designed by</span>{" "}
          <span
            onClick={scrollToTop}
            className="text-[#5E6AD2] font-semibold cursor-pointer"
          >
            BEYOND L<span className="text-[#cb2a93] font-semibold">OO</span>P
          </span>
        </div>
      </div>
    </div>
  );
};

export default FooterPage;
