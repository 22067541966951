import React, { useEffect, useRef, useState } from "react";

import { IoClose } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const ModalPage = ({ isOpen, onClose }) => {
  const [screenSize, setScreenSize] = useState("large");

  const modalRef = useRef(null);

  const handleClickOutsideModal = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideModal);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, []);

  const modalAnimation = isOpen
    ? "transition-opacity ease-in duration-300 opacity-100"
    : "transition-opacity ease-out duration-200 opacity-0 pointer-events-none";
  const notify = () => toast.success("Submitted");

  const data = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    description: "",
  };
  const [Inquiry, setInquiry] = useState(data);
  const handleData = (e) => {
    setInquiry({ ...Inquiry, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("http://193.203.184.114:3306/Inquiry.php", Inquiry)
      .then((response) => {
        console.log(response);
        setInquiry(data);
        notify();
        onClose(); // Auto close the modal after successful submission
      })
      .catch((error) => {
        console.error("There was an error submitting the form!", error);
      });
  };

  useEffect(() => {
    const updateLogoSize = () => {
      if (window.innerWidth < 400) {
        setScreenSize("x-small");
      } else if (window.innerWidth < 580) {
        setScreenSize("small");
      } else if (window.innerWidth < 900) {
        setScreenSize("medium");
      } else {
        setScreenSize("large");
      }
    };

    updateLogoSize();

    window.addEventListener("resize", updateLogoSize);

    return () => window.removeEventListener("resize", updateLogoSize);
  }, []);
  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center backdrop-blur bg-opacity-50 bg-gray-800 delay-75 ${modalAnimation}`}
    >
      <div className="absolute inset-0 flex items-center justify-center">
        <div
          className="bg-white p-8 rounded-md shadow-lg w-[50%]"
          ref={modalRef}
        >
          <div className="flex w-full justify-end">
            <ToastContainer className="custom-toast-container" />
            <IoClose
              className="text-2xl text-[#8e8e8e] cursor-pointer"
              onClick={onClose}
            />
          </div>
          <div>
            <p className="text-2xl font-semibold">Let's get in touch!</p>
          </div>
          <form className="flex flex-col w-full gap-4 mt-5">
            <div
              className={`flex justify-between ${
                screenSize === "small" || screenSize === "x-small"
                  ? "flex-col gap-4"
                  : ""
              }`}
            >
              <input
                placeholder="FirstName"
                type="text"
                name="firstName"
                value={Inquiry.firstName}
                onChange={handleData}
                className={`border border-[#5E6AD2] ${
                  screenSize === "small" || screenSize === "x-small"
                    ? "w-full"
                    : "w-[48%]"
                } py-2 px-2 outline-none rounded-md`}
              />

              <input
                placeholder="Last Name"
                type="text"
                name="lastName"
                value={Inquiry.lastName}
                onChange={handleData}
                className={`border border-[#5E6AD2] ${
                  screenSize === "small" || screenSize === "x-small"
                    ? "w-full"
                    : "w-[48%]"
                } py-2 px-2 outline-none rounded-md`}
              />
            </div>
            <input
              placeholder="Email"
              type="text"
              name="email"
              value={Inquiry.email}
              onChange={handleData}
              className="border border-[#5E6AD2] w-full py-2 px-2 outline-none rounded-md"
            />
            <input
              placeholder="Mobile Number"
              type="text"
              name="phoneNumber"
              value={Inquiry.phoneNumber}
              onChange={handleData}
              className="border border-[#5E6AD2] w-full py-2 px-2 outline-none rounded-md"
            />
            <textarea
              placeholder="Purpose of Visit"
              type="text"
              name="description"
              value={Inquiry.description}
              onChange={handleData}
              className="border border-[#2e3047] w-full py-2 px-2 resize-none h-[100px] overflow-hidden outline-none rounded-md"
            />
          </form>
          <div
            className={`flex gap-3 mt-4 ${
              screenSize === "small" || screenSize === "x-small"
                ? "flex-col"
                : ""
            }`}
          >
            <button
              className={`bg-[#8e8e8e] text-white py-2 px-6 rounded hover:bg-[#767575]`}
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className={`bg-[#5E6AD2] text-white py-2 px-6 rounded hover:bg-[#4a55bd]`}
              onClick={(e) => {
                e.preventDefault();
                notify();
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPage;
