import { FaPhone } from "react-icons/fa6";
import { IoChatboxEllipses } from "react-icons/io5";
import { BsFillPeopleFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaGlobe } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa6";
import { GrAndroid } from "react-icons/gr";
import { FaCartShopping } from "react-icons/fa6";
import { FaTools } from "react-icons/fa";
import { FaMicrochip } from "react-icons/fa6";
import { IoSettingsSharp } from "react-icons/io5";
import { HiSpeakerphone } from "react-icons/hi";
import { FaHandshake } from "react-icons/fa6";
import { TiWiFi } from "react-icons/ti";
import { FaCloud } from "react-icons/fa";
import { IoSchool } from "react-icons/io5";

import { FaDrumSteelpan } from "react-icons/fa";
import { BiSolidCarMechanic } from "react-icons/bi";
import { TbBoxModel } from "react-icons/tb";
import { SiAndroidauto } from "react-icons/si";
import { SiPowerautomate } from "react-icons/si";
import { LuBoxes } from "react-icons/lu";
import { RiLayoutMasonryFill } from "react-icons/ri";
import { RiFileList2Fill } from "react-icons/ri";
import { GiGears } from "react-icons/gi";
import { TbBrandDatabricks } from "react-icons/tb";
import { SiProtools } from "react-icons/si";

import { PiChatsFill } from "react-icons/pi";
import { FaUserCheck } from "react-icons/fa";
import { FaBusinessTime } from "react-icons/fa6";
import { PiCodeFill } from "react-icons/pi";
import { RiEmotionHappyFill } from "react-icons/ri";

export const HEADER_TABS = ["About", "Industries", "Services", "Contact"];

export const HELP_SUPPORT_CONTACT = [
  {
    headingIcon: <BsFillPeopleFill />,
    icon: <MdEmail />,
    heading: "General communication",
    subHeading:
      "For other queries, please get in touch with us via email or call us.",

    contact: "+91 7848943292",
    email: "info@beyondloop.in",
  },
  {
    headingIcon: <IoChatboxEllipses />,
    icon: <FaPhone />,
    heading: "Help & support",
    subHeading: "Ask product questions, report problems, or leave feedback.",
    contact: "+91 7504012736",

    email: "support@beyondloop.in",

  },
];

export const IT_SERVICES = [
  {
    icon: <FaGlobe />,
    heading: "WEB Application Development",
    subHeading:
      "Crafting digital experiences - Website & Web Application Development at it's finest!",
  },
  {
    icon: <FaUsers />,
    heading: "UI/UX Design",
    subHeading:
      "Revolutionize User Experiences - Ignite Your Brand with CuttingEdge UI/UX Design Magic!",
  },
  {
    icon: <GrAndroid />,
    heading: "Android/iOS Developnent",
    subHeading:
      "Unleash Your App's Potential - Expert Android & iOS Development, Unmatched Results!!",
  },
  {
    icon: <FaCartShopping />,
    heading: "E-Commerce Development",
    subHeading:
      "Your E-Commerce Vision Realized - Crafted to Perfection, Seamless Shopping Experiences!",
  },
  {
    icon: <FaTools />,
    heading: "Product Testing & QA",
    subHeading:
      "Quality at Every Step - Trustworthy Product Testing and QA, Ensuring Flawless Performance!",
  },
  {
    icon: <FaMicrochip />,
    heading: "Technology Consulting",
    subHeading: "Guiding Your Success with Expert Technology Consulting!",
  },
  {
    icon: <IoSettingsSharp />,
    heading: "Custom Software Development",
    subHeading:
      "Tailored to Perfection - Unleash Your Vision with Custom Software Development!",
  },
  {
    icon: <HiSpeakerphone />,
    heading: "Digital Marketing",
    subHeading:
      "Unleash Your Digital Potential - Elevate Your Brand with Dynamic Digital Marketing!",
  },
  {
    icon: <FaHandshake />,
    heading: "Support Services",
    subHeading:
      "Your dedicated tech support partner, solving problems with expertise!",
  },
  {
    icon: <IoSchool />,
    heading: "Educational Services",
    subHeading:
      "Digitization can be considered as a shift from pen paper based education to online learning!",
  },
  {
    icon: <TiWiFi />,
    heading: "IOT, AIOT & IIOT",
    subHeading: "Connecting the future: IoT, AIoT, and IIoT solutions for you!",
  },
  {
    icon: <FaCloud />,
    heading: "Cloud Services",
    subHeading:
      "Cloud services are application and infrastructure resources that exist on the Internet!",
  },
];

export const MECHANICAL_SERVICES = [
  {
    icon: <BiSolidCarMechanic />,
    heading: "Tool and Die Design",
    subHeading:
      "We design and develope custom tools for both casting and plate material to meet our customer needs!",
  },
  {
    icon: <FaDrumSteelpan />,
    heading: "Press Tool Design",
    subHeading:
      "Our manufacturing and quality engineers also work together to improve their design, tooling and fabrication!",
  },
  {
    icon: <TbBoxModel />,
    heading: "Part Modeling",
    subHeading:
      "We provide 3D part modeling solutions with high Precision and accuracy of part dimensions!",
  },
  {
    icon: <SiAndroidauto />,
    heading: "CAD / CAM Design Solution",
    subHeading:
      "We provides CAD/CAM services with advance engineering and manufacturing processes!",
  },
  {
    icon: <SiPowerautomate />,
    heading: "Surface Modeling",
    subHeading:
      "For delivering best surface designs we apply procedural surfaces, validate imperfections, and smoothness!",
  },
  {
    icon: <GiGears />,
    heading: "Product Design and Analysis",
    subHeading:
      "We take responsibilites for the mechanical design of a new product, with specific reference to the mechanical function of the final product!",
  },
  {
    icon: <LuBoxes />,
    heading: "2D & 3D Modeling Support",
    subHeading:
      "We provide 2D & 3D models design to develop and create the construction documentation!",
  },
  {
    icon: <RiLayoutMasonryFill />,
    heading: "Layout Design",
    subHeading:
      "Precision in the design is critical, as the punches and dies must maintain proper clearance to manufacture quality parts!",
  },
  {
    icon: <RiFileList2Fill />,
    heading: "Bill Of Material Creation",
    subHeading:
      "We provide list of the items needed to create a product as well as the instructions on how to assemble that product!",
  },
  {
    icon: <TbBrandDatabricks />,
    heading: "Assembly Modeling",
    subHeading:
      "This is achieved with numerous tests done so that the assembly created fulfills the design intent with no further interference!",
  },
  {
    icon: <SiProtools />,
    heading: "Fixture Design",
    subHeading:
      "We create highly accurate fixtures meeting the required specification and adhering to respective industry needs!",
  },
];

export const REVIEWS = [
  {
    img: "JEEV.jpg",
    name: "Jeevan Bhuyan",
    role: "Senior Software Engineer",
    review:
      "The customer service is truly exceptional, marked by several distinctive aspects.",
  },

  {
    img: "KAVYA.jpg",
    name: "Chinmayee Pani",
    role: "Software Engineer",
    review:
      "Their dedication to customer satisfaction is evident in their efforts to address every query and concern is awesome.",
  },
  {
    img: "JEEV.jpg",
    name: "Bhuvaneshwar kumar",
    role: "Software Engineer",
    review:
      "Seamless IT solutions that exceeded our expectations.",
  },

  {
    img: "KAVYA.jpg",
    name: "Chinmayee Pani",
    role: "Software Engineer",
    review:
      "Top-notch expertise, reliable and efficient service.",
  },
  {
    img: "JEEV.jpg",
    name: "Jeevan Bhuyan",
    role: "Senior Software Engineer",
    review:
      "Reliable support with excellent customer service.",
  },

  {
    img: "KAVYA.jpg",
    name: "Chinmayee Pani",
    role: "Software Engineer",
    review:
      "Exceptional services, responsive and knowledgeable team.",
  },
];

export const CLIENTS = [
  "IOTA.png",
  "SKYYSKILL.png",
  "DAICHI.png",
  // "PHOENIX.png",
  // "ISS.png",
];

export const CORE_VALUES = [
  {
    heading: "Our Values",
    subHeading:
      "We emphasize on quality web application development and mobile application development by providing sheer commitment to our clients’ projects.",
  },

  {
    heading: "Our Mission",
    subHeading:
      "To provide best-in-class web and mobile application development services for distinct business industries from small to medium companies.",
  },

  {
    heading: "Our Vission",
    subHeading:
      "To take innovation in web and mobile application development to greater standards and establish our quality service with the global customers.",
  },
];

export const APPROACHES = [
  {
    heading: "Built for you",
    subHeading:
      "We create products for real people. If something doesn't matter to you, it doesn't matter to us.",
  },

  {
    heading: "Product before promises",
    subHeading:
      "Instead of talking about what may be possible, we let you experience it yourself.",
  },

  {
    heading: "Process built for progress",
    subHeading:
      "Every project presents its goals and opportunities. Our process helps us constantly learn and improve.",
  },
];

export const CHOOSE_US = [
  {
    percentage: "88%",
    subHeading: "Accuracy",
  },

  {
    percentage: "70%",
    subHeading: "Time Saved",
  },

  {
    percentage: "10%",
    subHeading: "Effective Costs",
  },
];

export const REASONS = [
  {
    icon: <PiChatsFill />,
    heading: "Quick response",
    paragraph:
      "We can log in to your PC or server remotely and resolve many issues immediately without the wait for a technician to travel to your location.",
  },
  {
    icon: <FaUserCheck />,
    heading: "No geek speak",
    paragraph:
      "You deserve to have your questions answered. Our technicians will clearly explain what is happening so you understand.",
  },
  {
    icon: <FaBusinessTime />,
    heading: "Business savvy",
    paragraph:
      "We design, evaluate and justify technology solutions from a thorough understanding of the business benefit for your company.",
  },
  {
    icon: <PiCodeFill />,
    heading: "One Stop Shop",
    paragraph:
      "We handle all aspects of your IT and Mechanical infrastructure including hardware, software management and any other related technology needs.",
  },
  {
    icon: <RiEmotionHappyFill />,
    heading: "100% Satisfaction Guarantee",
    paragraph:
      "We want you to be completely satisfied with our services. We will do whatever it takes to make you happy. No hassles, no problems.",
  },
];

export const INDUSTRIES = [
  "Increased company value",
  "Reduces training overheads",
  "Accelerate growth",
  "Increases employees efficiency and productivity",
  "Streamline business operations and accounts",
  "Effective communication with customers, suppliers and partners",
  "Reduction in cost",
];

export const INDUSTRIES_WE_SERVE = [
  "Retail",
  "Real Estate",
  "Energy",
  "Banking",
  "Travel & Hospitality",
  "Healthcare",
  "Insurance",
  "Manufacturing",
  "Media & Entertainment",
  "Education",
  "Automotive",
  "E-commerce",
];
