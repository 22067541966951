import React, { useEffect, useState } from "react";
import { APPROACHES, CHOOSE_US, CORE_VALUES, REASONS } from "../constants";

const AboutPage = ( { innerRef } ) => {
  
  const [ screenSize, setScreenSize ] = useState( "large" )

  useEffect( () => {
    const updateLogoSize = () => {
      if ( window.innerWidth < 500 ) {
        setScreenSize( 'small' )
      } else if ( window.innerWidth < 600 ) {
        setScreenSize( 'small-medium' )
      } else if ( window.innerWidth < 1000 ) {
        setScreenSize( 'medium' )
      } else if ( window.innerWidth < 1200 ) {
        setScreenSize( 'medium-large' )
      } else {
        setScreenSize( 'large' )
      }
    };

    updateLogoSize();

    window.addEventListener( 'resize', updateLogoSize );

    return () => window.removeEventListener( 'resize', updateLogoSize );
  }, [] );

  return (
    <div
      id='aboutPage'
      className='flex flex-col min-h-full min-w-full items-center gap-20 py-20 bg-[#161618]'
      ref={innerRef}
    >
      <p className={`text-white font-semibold ${screenSize === 'x-small' ? 'text-2xl' : screenSize === 'small' ? 'text-3xl' : 'text-4xl'}`}>ABOUT US</p>
      <div className={`w-[80%] ${(screenSize === 'large')?'flex justify-between items-center':'flex flex-col items-center'}`}>
        <div className={`h-full flex flex-col justify-start gap-8 ${screenSize === 'large' ? 'w-[50%]' : 'w-100%'}`}>
          <h2 className={`text-[#5E6AD2] ${screenSize === 'x-small' ? 'text-2xl' : screenSize === 'small' ? 'text-3xl' : 'text-4xl'}`}>
            Growth Through Technology : Empowering Your Business with Innovative
            Solutions
          </h2>
          <p className={`text-[#8A8F98]`}>
            Beyond Loop is a Service based Outsourcing Company that loves
            developing futuristic & custom Web and Mechanical services. We bring
            together decades of tech expertise to build robust and
            cost-effective services for Startups and SMBs. Beyond Loop is
            committed to achieving the client’s business goals on time and
            budget. We prioritize continuous learning to enhance our tech
            skills, expertise and ideas. We are leaders in offering dedicated
            Web and Mechanical developers to our clients worldwide. The highly
            skilled developers at Beyond Loop deliver cutting-edge
            business-centric Services with 100% work satisfaction. Beyond Loop
            goes far above and beyond to add growth and value to your business
            in this cut-throat competition.
          </p>
        </div>

        <div className={` ${screenSize !== 'large' ? 'mt-14 w-[70%]' : 'w-[40%]'}`}>
          <img src='About Image.png' alt='img' className='object-fill' />
        </div>
      </div>

      <div className={`w-full gap-24 px-20 py-10 items-center ${screenSize !== 'large' ? 'flex flex-wrap justify-center' : 'flex'}`}>
        {CORE_VALUES.map((item) => {
          return (
            <div className='flex flex-col justify-start w-[30%] gap-3 min-w-[17rem]'>
              <h3 className='text-[#5E6AD2] font-semibold'>{item.heading}</h3>
              <hr className='w-12' />
              <p className='text-[#8A8F98]'>{item.subHeading}</p>
            </div>
          )
        })}
      </div>

      <div className='w-full border-solid border bg-[#1D1D1F] border-[#2E2E30]'>
        <div className='flex flex-col gap-5 items-center pt-5'>
          <div className={`${screenSize==='small-medium'||screenSize==='small'?'flex flex-wrap justify-center gap-1':''}`}>
            <span className={`text-white ${screenSize === 'small-medium' || screenSize==='small' ? 'text-2xl' :'text-3xl'} font-semibold`}>
              Why you should Choose
            </span>{' '}
            <span className={`text-[#5E6AD2] ${screenSize === 'small-medium' || screenSize === 'small' ? 'text-2xl' :'text-3xl'} font-semibold`}>
              Beyond Loop ?
            </span>
          </div>

          <div className={`${screenSize === 'small' ?'flex flex-wrap justify-center':'flex justify-center'} gap-20 py-10`}>
            {CHOOSE_US.map((item) => {
              return (
                <div className='flex flex-col items-center gap-2'>
                  <h1 className='text-[#5E6AD2] text-3xl font-semibold'>
                    {item.percentage}
                  </h1>
                  <p className='text-white text-base'>{item.subHeading}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className={`w-[80%] ${screenSize==='large'?'flex justify-between items-center':'flex flex-col items-center'}`}>
        <div className={`${screenSize === 'large' ? 'w-[30%]' : 'w-[40%]'}`}>
          <img src='Rocket.png' alt='img' className='object-fill' />
        </div>
        <div className={`${screenSize === 'large' ? 'w-[60%]' :'w-[80%]'} h-full flex flex-col justify-start gap-8`}>
          <h2 className={`text-[#5E6AD2] ${screenSize === 'x-small' ? 'text-2xl' : screenSize === 'small' ? 'text-3xl' : 'text-4xl'} font-semibold ${screenSize === 'large' ? '' : 'text-center mt-20'}`}>
            Our Approach
          </h2>
          <div className={`flex flex-col justify-start ${screenSize==='large'?'gap-3':'gap-6'}`}>
            {APPROACHES.map((item) => {
              return (
                <div className='flex flex-col justify-start gap-2'>
                  <h3 className='text-white text-2xl'>{item.heading}</h3>
                  <hr className='w-12' />
                  <p className='text-[#8A8F98]'>{item.subHeading}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className='flex flex-col w-full items-center gap-2'>
        <h1 className={`text-[#5E6AD2] ${screenSize === 'x-small' ? 'text-2xl' : screenSize === 'small' ? 'text-3xl' : 'text-4xl'} text-center line-clamp-6`}>
          5 REASONS TO PARTNER WITH BEYOND LOOP
        </h1>

        <div class={`${screenSize==='large'?'flex flex-wrap justify-center':'flex flex-wrap justify-center'} gap-8 mt-10`}>
          {REASONS.map((item) => {
            return (
              <div className='border-solid border bg-[#1D1D1F] border-[#2E2E30] p-4 h-72 w-60 cursor-pointer rounded-md transition-transform duration-500 transform hover:-translate-y-3 hover:shadow-gray-700 shadow'>
                <div className='flex flex-col gap-4 items-start'>
                  <div className='text-[#5E6AD2] text-3xl'>{item.icon}</div>
                  <p className='text-white font-semibold'>{item.heading}</p>
                  <p className='text-[#8A8F98]'>{item.paragraph}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
};

export default AboutPage;
