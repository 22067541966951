import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import ContactPage from "./components/ContactPage";
import HeaderContainer from "./modules/HeaderContainer";
import MainContent from "./modules/MainContent";
import ITServicePage from "./components/ITServicePage";
import MechanicalServicePage from "./components/MechanicalServicePage";
import FooterPage from "./components/FooterPage";
import IndustriesPage from "./components/IndustriesPage";
import { useEffect, useRef, useState } from "react";
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";


function App() {

  const [ showScrollTop, setShowScrollTop ] = useState( false )
  const [ shouldScrollToAbout, setShouldScrollToAbout ] = useState( false );

  const location = useLocation()

  const aboutRef = useRef( null )
  const mainContentRef = useRef( null )

  const scrollToAbout = () => {
    aboutRef.current.scrollIntoView( { behavior: 'smooth' } )
    setShouldScrollToAbout( false )
  }

  const scrollToTop = () => {
    mainContentRef.current.scrollTo( { top: 0, behavior: 'smooth' } )
  }

  useEffect( () => {
    const handleScroll = () => {
      const position = mainContentRef.current.scrollTop
      setShowScrollTop( position > 300 )
    }

    const scrollContainer = mainContentRef.current
    if ( scrollContainer ) {
      scrollContainer.addEventListener( 'scroll', handleScroll )
    }

    return () => {
      if ( scrollContainer ) {
        scrollContainer.removeEventListener( 'scroll', handleScroll )
      }
    }
  }, [] )

  useEffect( () => {
    if ( shouldScrollToAbout ) {
      scrollToAbout();
    }
  } )
  useEffect( () => {
    if ( !shouldScrollToAbout ) {

      scrollToTop()
    }
  }, [ location ] )


  return (
    <div
      className='bg-mainbg'
      style={{
        background: 'linear-gradient(0deg, #040615 60%, #0c274c 100%)',
        maxHeight: '100vh',
        overflow: 'auto',
      }}
      ref={mainContentRef}
    >

      <div className='absolute top-0'>
        <HeaderContainer
          scrollToAbout={scrollToAbout}
          mainContentRef={mainContentRef}
          setShouldScrollToAbout={setShouldScrollToAbout}
        />
      </div>
      <Routes>
        <Route path='/' element={<MainContent innerRef={aboutRef} />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/ITServices' element={<ITServicePage />} />
        <Route path='/industries' element={<IndustriesPage />} />
        <Route path='/MechanicalServices' element={<MechanicalServicePage />} />
      </Routes>
      <FooterPage scrollToAbout={scrollToAbout} setShouldScrollToAbout={setShouldScrollToAbout} scrollToTop={scrollToTop} />
      {showScrollTop && (
        <div
          className="absolute right-10 bottom-14 cursor-pointer py-1 px-1 bg-[#F1E7FE] rounded-full transition-all duration-300 ease-in-out hover:translate-y-[-5px]"
          style={{ boxShadow: 'rgba(159, 90, 253, 5) 2px 70px 100px -10px, rgba(159, 90, 253, 5) 2px 40px 80px -50px, rgba(159, 90, 253, 5) 2px 4px 7px 0px inset' }}
          onClick={scrollToTop}
        >
          <MdOutlineKeyboardDoubleArrowUp className="h-8 w-8" />
        </div>
      )}
    </div>
  )
}

export default App;
