import React, { useEffect, useState } from 'react'
import { FaPhone } from 'react-icons/fa6'
import { MdEmail } from 'react-icons/md'
import { HELP_SUPPORT_CONTACT } from '../constants'

const ContactPage = () => {
  const [ screenSize, setScreenSize ] = useState( "large" )

  useEffect( () => {
    const updateLogoSize = () => {
      if ( window.innerWidth < 400 ) {
        setScreenSize( 'x-small' )
      }else if ( window.innerWidth < 580 ) {
        setScreenSize( 'small' )
      } else if ( window.innerWidth < 900 ) {
        setScreenSize( 'medium' )
      } else {
        setScreenSize( 'large' )
      }
    };

    updateLogoSize();

    window.addEventListener( 'resize', updateLogoSize );

    return () => window.removeEventListener( 'resize', updateLogoSize );
  }, [] );


  return (
    <>
      <div
        className='flex flex-col items-center justify-center gap-20 py-10'
        style={{ backgroundColor: 'g', minHeight: 'calc(100vh - 80px)' }}
      >
        <div className='flex flex-col w-full items-center gap-2 mt-2'>
          <h1
            className={`font-semibold text-center ${screenSize === 'x-small' ? 'text-2xl' : screenSize === 'small' ? 'text-3xl' : 'text-4xl'}`}
            style={{
              color: '#FFFFFF',
              display: 'inline-block',
              lineHeight: '80px',
            }}
          >
            How can we help you ?
          </h1>
          <p className={`text-[#8A8F98] font-semibold ${screenSize === 'x-small' ? 'text-[17px] w-[18rem]' : screenSize === 'medium' || screenSize === 'large' ? 'w-[30rem] text-xl' :'w-[25rem] text-md'} text-center`}>
            Get in touch with our support teams for demos, onboarding support,
            or product questions.
          </p>
        </div>

        <div className={`w-full flex justify-center ${screenSize === 'medium' || screenSize === 'small' || screenSize==='x-small' ? 'flex-col items-center' : ''} gap-10`}>
          {HELP_SUPPORT_CONTACT.map((item) => {
            return (
              <div
                className='flex flex-col border-solid border bg-[#1D1D1F] border-[#2E2E30]	py-3 px-5 rounded-xl gap-2 max-w-[90%]'
                style={{ cursor: 'pointer' }}
              >
                <div className='flex items-center text-white gap-2'>
                  <div className='text-xl text-[#479E73]'>
                    {' '}
                    {item.headingIcon}
                  </div>
                  <h2 className='text-lg font-semibold'>{item.heading}</h2>
                </div>
                <p className={`text-white ${screenSize==='x-small'?'w-64':screenSize === 'small' ? 'w-72' : 'w-96'}`}>{item.subHeading}</p>

                <div className='flex items-center text-white gap-3 mt-3'>
                  <FaPhone className='text-sm text-[#5E6AD2]' />
                  <span className='text-xs font-bold text-[#8A8F98]'>
                    {item.contact}
                  </span>
                </div>

                <div className='flex items-center text-white gap-3 mt-3'>
                  <MdEmail className='text-lg text-[#5E6AD2]' />
                  <span className='text-xs font-bold text-[#8A8F98]'>
                    {item.email}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ContactPage
