import React, { useEffect, useState } from "react";
import { CLIENTS } from "../constants";

const ClientsPage = () => {

  const [ screenSize, setScreenSize ] = useState( "large" )

  useEffect( () => {
    const updateLogoSize = () => {
      if ( window.innerWidth < 400 ) {
        setScreenSize( 'x-small' )
      } else if ( window.innerWidth < 580 ) {
        setScreenSize( 'small' )
      } else if ( window.innerWidth < 800 ) {
        setScreenSize( 'medium' )
      } else {
        setScreenSize( 'large' )
      }
    };

    updateLogoSize();

    window.addEventListener( 'resize', updateLogoSize );

    return () => window.removeEventListener( 'resize', updateLogoSize );
  }, [] );

  return (
    <div className="py-7 pt-12 bg-black min-h-80 w-full flex flex-col justify-center items-center gap-8">
      <div>
        <p className={`text-[#5E6AD2] font-semibold ${screenSize === 'x-small' ?'text-2xl':'text-3xl'}`}>OUR HAPPY CUSTOMERS</p>
      </div>
      <hr className="w-20"/>

      <div className={`px-10 w-[100%] ${screenSize==='large'?"flex justify-center":" flex flex-wrap justify-center"} gap-8`}>
        {CLIENTS.map((item) => {
          return (
            <div className="h-40 w-40 flex items-center">
              <img src={item} className="object-cover" alt="img" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ClientsPage;
