import React, { useEffect, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import ModalPage from "./ModalPage";

const LandingPage = () => {
  const [ isOpen, setIsOpen ] = useState( false );
  
  const [ screenSize, setScreenSize ] = useState( "large" )

  useEffect( () => {
    const updateLogoSize = () => {
      if ( window.innerWidth < 640 ) {
        setScreenSize( 'small' )
      } else if ( window.innerWidth < 900 ) {
        setScreenSize( 'medium' )
      } else {
        setScreenSize( 'large' )
      }
    };

    updateLogoSize();

    window.addEventListener( 'resize', updateLogoSize );

    return () => window.removeEventListener( 'resize', updateLogoSize );
  }, [] );

  const openModal = () => {
      setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
};
  return (
    <div style={{minHeight: '100vh'}}>
      <div
        className="flex flex-col items-center justify-center gap-28"
        style={{ height: "calc(100vh)" }}
      >
        <div className="flex flex-col w-full items-center gap-6">
          <h1
            className={`font-extrabold text-center tracking-wide ${screenSize === 'small' ? 'text-5xl' : 'text-6xl'}`}
            style={{
              background: "linear-gradient(to right, #ffffff, #0c274c)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              display: "inline-block",
              lineHeight: "80px",
            }}
          >
            Design. Collaborate. Release.
          </h1>
          <p className={`text-white ${screenSize === 'small' ? 'text-lg w-[20rem]' : 'text-xl w-[40rem]'} text-center`}>
            Quality in a service or product is not what you put into it. It is
            what the client or customer get out of it.
          </p>
        </div>

        <button className="flex items-center justify-center bg-[#5E6AD2] text-white px-6 py-2 rounded-full text-lg font-semibold " onClick={openModal}>
          Let's Loop <MdKeyboardArrowRight />
        </button>
        <ModalPage isOpen={isOpen} onClose={closeModal}/>
      </div>
      

    </div>
  );
};

export default LandingPage;
